body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
}

/* .bg-decima {
  background-color: #20232a!important;
} */

/* swal2 custom classes */

.swal2-container {
  z-index: 1301 !important;
}

.decima-swall-custom-btn-padding {
  padding: 6px 12px!important;
}

.decima-swall-font-size-small-title {
  font-size: 1.4em !important;
}

.decima-swall-font-size-small-content {
  font-size: 1.0em !important;
}
